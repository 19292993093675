import 'slick-carousel/slick/slick'

$('[data-fancybox]').fancybox({
    padding: 0,
    helpers: {
        overlay: {
          locked: false
        }
    },
    'beforeLoad': function(){
        console.log('before load')
      disable_scroll();
        },
     'afterClose': function(){
        console.log('before closed')
       enable_scroll();
      }
    // Options will go here
});

/* Add target blank to trix editor */
$(document).on("turbolinks:load",function(){

    // Contact us modal
    let contactsModal = document.getElementById("contactSentModal")
    let closeBTN = document.getElementsByClassName("close")[0]
    if(closeBTN) {
        closeBTN.onclick = function() {
            contactsModal.style.display = 'none'
        }
    }
    window.onclick = function(event) {
        if (event.target == contactsModal) {
            contactsModal.style.display = 'none'
        }
    }

    $(".trix-content a").click(function(e) {
        if($(this).attr("href").match(/http/)){
            // console.log($(this).attr("href"))
            // e.preventDefault()
            $(this).attr("target","_blank");
        }
    });
});

$(document).on("turbolinks:load",function(){
  $('[data-toggle="tooltip"]').tooltip()
})

// Mobile Menu Toggle Function
$(document).on("turbolinks:load",function(){
    $(".x-menu-toggler").click(function(){
        $(this).toggleClass("active");
        $(this).parents(".x-header").toggleClass("active");
    });

    $(".pricing-table .lbl").click(function(){
        $(this).parents(".plan-toggler").toggleClass("active");
    });
});


// Smooth Scroll
$(document).on("turbolinks:load",function(){
    // $( 'a.free-trial-button' ).on( 'click', function(e){
    //   var href = $(this).attr( 'href' );
    //   e.preventDefault();
    //
    //   $( 'html, body' ).animate({
    //         scrollTop: $( href ).offset().top
    //   }, '300' );
    //
    // });

    // scrolling = docuemnt.querySelectorAll('#scrolling');
    // $('#scrolling').each(function(index) {
    //     console.log('index', index);
    //     $(this).on('click', function(e) {
    //         e.preventDefault();
    //         var href = $(this).attr('href');
    //         console.log('clicked ', href);
    //
    //         $( 'html, body' ).animate({
    //             scrollTop: $( href ).offset().top
    //         }, '300' );
    //     });
    // });

});


if($('.testimonials')) {
    $(document).on("turbolinks:load",function(){

        var slickData = document.querySelector(".testimonials")
        if(slickData) {
            var slickJson = JSON.parse(slickData.dataset["slick"])

             $('.testimonials').slick({
              infinite: true,
              speed: 300,
              slidesToShow: 1,
              prevArrow: '<span class="slick-prev-arrow"><img src='+slickJson.prevArrows+' alt="previous testimonial"></span>',
              nextArrow: '<span class="slick-next-arrow"><img src='+slickJson.nextArrows+' alt="next testimonial"></span>'
            });
        }
    })
}


/* Fix for jumping to the top on anchor click */
$(document).on("turbolinks:load",function(){
    var links = document.querySelectorAll('a')
    links.forEach((e) => {
        let anchor = e.getAttribute("href")
        let fancybox = e.getAttribute("data-fancybox")

        // Find all links for fancybox with attributes data-fancybox
        if(fancybox != null) {
            e.dataset.turbolinks = 'false'
        }
        // Find all anchor links
        if(String(anchor).match(/\#/)) {
            // Add data-turbolinks = false
            e.dataset.turbolinks = 'false'
        }
    })
})
