
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "has_price" ]

    toggle(e) {
        e.preventDefault()
        if(this.sourceTarget.checked == true) {
            this.has_priceTarget.style.display = "block";
        }else{
            this.has_priceTarget.style.display = "none";
        }
    }
}
