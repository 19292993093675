
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "month", "year" ]

    /*
     * checked
     * true = monthly
     * false = yearly
     */
    toggle(e) {
        e.preventDefault()
        let selected = this.sourceTarget.checked
        if(selected == true) {
            this.monthTargets.forEach( (elem) => { elem.style.display = 'block' } )
            this.yearTargets.forEach( (elem) => { elem.style.display = 'none' } )
        }else{
            this.yearTargets.forEach( (elem) => { elem.style.display = 'block' } )
            this.monthTargets.forEach( (elem) => { elem.style.display = "none" } )
        }
    }
}
