import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

// Sortable price category list
// route: admin/price_categories/:id/move
export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            filter: '.disabled',
            draggable: '.item',
            // preventOnFilter: true,
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let id = event.item.dataset.sortPriceItemId
        let data = new FormData()
        data.append('sorting', event.newIndex + 1)

        Rails.ajax({
            url: this.data.get('url').replace(":id", id),
            type: 'PATCH',
            data: data
        })
    }
}
