import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "email", "errorOutput" ]

    sendEmail(e) {
        e.preventDefault()
        let email = this.emailTarget.value
        if(this.emailValid(email) == true) {
          this.errorOutputTarget.textContent = ""
            window.open(
                `https://app.coachvantage.com/users/sign_up?email=${email}`,
                '_blank'
            );
        }else{
          this.errorOutputTarget.textContent = "Please enter valid email"
        }
    }

    emailValid(email) {
        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat)) {
            return true
        }
        return false
    }
}
