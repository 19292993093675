
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "single", "multiple" ]

    toggle(e) {
        e.preventDefault()
        let selected = this.sourceTarget.value
        if(selected.match(/single/)) {
            this.singleTarget.style.display = "block";
            this.multipleTarget.style.display = "none";
        }else{
            this.multipleTarget.style.display = "block";
            this.singleTarget.style.display = "none";
        }
    }
}
