/* This will wrap img into imageWrapper div tag
 * Check if this is post with content class and will not be assigned to
 * any other tags
 */
$(document).on("turbolinks:load",function(){
    var content = document.querySelector('.content') // get content class
    var images = document.querySelectorAll(".content img") // get all images
    var postTitle = document.querySelector('.post-title') // Get post title

    if(content && images) { // if content and images present?
        images.forEach(function(image) {

            let caption = image.getAttribute('title') // get caption if present?
            let source = image.getAttribute('src') // get source of image
            let alt = image.getAttribute('alt') // get alt value of image

            // If image uploaded with CKEditor, wrap it
            if(source.match(/rails\/active_storage/)) {
                let wrapper = document.createElement('div')
                wrapper.setAttribute("id", "imageWrapper");
                image.parentNode.insertBefore(wrapper, image)
                wrapper.appendChild(image)
            }

            // Add paragraph tag with caption inside
            if(caption) {
                let paragraph = document.createElement('p');
                let text = document.createTextNode(image.getAttribute('title'))
                paragraph.appendChild(text)
                image.after(paragraph);
            }

            // If alt of image is empty, use post title
            if(alt === '') {
                image.setAttribute('alt', postTitle.innerText)
            }

            // If image bigger than .content width, resize it to width of .content
            if(image.offsetWidth > content.offsetWidth) {
                image.style.width = '100%'
                image.style.height = ''
            }
        })
    }
})
