
/* This will wrap img into imageWrapper div tag
 * Check if this is post with content class and will not be assigned to
 * any other tags
 */
$(document).on("turbolinks:load",function(){
    var content = document.querySelector('.content') // get content class
    var tables = document.querySelectorAll(".content table") // get all table
    if(content && tables) {
        tables.forEach(function(table) {
            let wrapper = document.createElement('div')
            wrapper.setAttribute("id", "tableWrapper");
            table.parentNode.insertBefore(wrapper, table)
            wrapper.appendChild(table)
        })

    // <div class="swipe-icon">
    //   <div class="arrowicon">
    //     <svg-icon icon-class="swipe" class="finger" />
    //     <svg-icon icon-class="swipe_arrow" class="iconarrow" />
    //   </div>
    //   <span class="pd-l-2">Drag left &amp; right to read</span>
    // </div>

    }
})
