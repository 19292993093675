import { Controller } from '@hotwired/stimulus';
import KeenSlider  from 'keen-slider'

export default class extends Controller {
    connect() {
         this.slider = new KeenSlider(document.getElementById('myslider'), {
            loop: true,
            mode: "free-snap",
            slides: {
                perView: 3,
                spacing: 35,
                origin: "center",
            },
            created: () => {
                this.initializeSlider();
            },
            slideChanged: (s) => {
                this.updateNavigation();
            },
            breakpoints: {
                '(max-width: 768px)': {
                    loop: true,
                    slides: {
                        perView: 2,
                        spacing: 30,
                    },
                },
                '(max-width: 500px)': {
                    loop: true,
                    slides: {
                        perView: 1.4,
                        spacing: 30,
                        origin: "center",
                    },
                },
            },
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false

                function clearNextTimeout() {
                    clearTimeout(timeout)
                }

                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return

                    timeout = setTimeout(() => {
                        slider.next()
                    }, 3000)
                }

                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })

                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]);
    }

    initializeSlider() {
        // Delay the setup of navigation to ensure slider is fully initialized
        setTimeout(() => {
            if (this.slider && this.slider.track && this.slider.track.details) {
                this.setupNavigation();
            } else {
                console.error("Slider not properly initialized or missing track details.");
            }
        }, 100);  // 100ms delay to ensure the slider is fully initialized
    }

    setupNavigation() {
        const wrapper = document.createElement("div");
        wrapper.classList.add("navigation-wrapper");
        this.element.parentNode.insertBefore(wrapper, this.element.nextSibling);

        console.log("Navigation", this.element.dataset.navigation);
        if(this.element.dataset.navigation === "arrows") {

            // Create unique arrows for each slider
            this.arrowLeft = document.createElement("div");
            this.arrowLeft.classList.add("arrow", "arrow--left");
            this.arrowLeft.addEventListener("click", () => this.slider.prev());  // Bind to specific slider

            this.arrowRight = document.createElement("div");
            this.arrowRight.classList.add("arrow", "arrow--right");
            this.arrowRight.addEventListener("click", () => this.slider.next());  // Bind to specific slider

            wrapper.appendChild(this.arrowLeft);
            wrapper.appendChild(this.arrowRight);
        }else if(this.element.dataset.navigation === "dots") {
            // Create unique dots for each slider
            this.dots = document.createElement("div");
            this.dots.classList.add("dots");

            this.slider.track.details.slides.forEach((_e, idx) => {
                const dot = document.createElement("div");
                dot.classList.add("dot");
                dot.addEventListener("click", () => this.slider.moveToIdx(idx));
                this.dots.appendChild(dot);
            });

            wrapper.appendChild(this.dots);
        }

        this.updateNavigation();  // Initial update to sync classes
    }

    updateNavigation() {
        if (!this.slider || !this.slider.track || !this.slider.track.details) {
            // console.error("Slider not properly initialized or missing track details.");
            return;
        }

        const slide = this.slider.track.details.rel;

        // Update arrow disabled states
        if (this.arrowLeft) {
            slide === 0
                ? this.arrowLeft.classList.add("arrow--disabled")
                : this.arrowLeft.classList.remove("arrow--disabled");
        }

        if (this.arrowRight) {
            slide === this.slider.track.details.slides.length - 1
                ? this.arrowRight.classList.add("arrow--disabled")
                : this.arrowRight.classList.remove("arrow--disabled");
        }

        // Update active dot
        if (this.dots) {
            Array.from(this.dots.children).forEach((dot, idx) => {
                idx === slide
                    ? dot.classList.add("dot--active")
                    : dot.classList.remove("dot--active");
            });
        }
    }

    disconnect() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }
}
